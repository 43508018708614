import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://forthebadge.com"
      }}><img parentName="a" {...{
          "src": "https://forthebadge.com/images/badges/uses-html.svg",
          "alt": "forthebadge"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://forthebadge.com"
      }}><img parentName="a" {...{
          "src": "https://forthebadge.com/images/badges/uses-css.svg",
          "alt": "forthebadge"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://forthebadge.com"
      }}><img parentName="a" {...{
          "src": "https://forthebadge.com/images/badges/uses-js.svg",
          "alt": "forthebadge"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://forthebadge.com"
      }}><img parentName="a" {...{
          "src": "https://forthebadge.com/images/badges/made-with-vue.svg",
          "alt": "forthebadge"
        }}></img></a></p>
    <p><a parentName="p" {...{
        "href": "https://createtierlist.web.app/"
      }}>{`Create Tier List`}</a>{` lets you create a "tier list" of any thing you want! Chose from a preset or add your own items. Use the default tiers, or create your own! Share a link to your tier list with someone else (or don't, I don't care).  `}</p>
    <p>{`It uses Vue.js & Vuetify, is hosted with Firebase Hosting, and is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/createtierlist"
      }}>{`on Github`}</a>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      